import React from 'react';
import { HtmlCode } from '../richTextOptions';

import PPC from './Form/PPC';
import OrderPrintedMaterials from './Form/OrderPrintedMaterials';
import PracticeInsightsNewsletter from './Form/PracticeInsightsNewsletter';
import HRProfessionals from './Form/HRProfessionals';
import Brokers from './Form/Brokers';
import VetHelplineSignup from './Form/VetHelplineSignup'
import VeterinariansSignUp from './Form/VeterinariansSignUp';
import BenefitsbrokerssignUp from './Form/BenefitsbrokerssignUp';

const ContentfulBlockSubmitForm = (form) => {
     if (form.isIframeForm) {
          return (
               <>
                    {
                         form.script && <HtmlCode data={form.script}></HtmlCode>
                    }
               </>
          )
     } else {
          var content = '';
          switch (form.template) {
               case 'PPC':
                    content = (<PPC contentName={form.contentName} />);
                    break;
               case 'Order printed materials':
                    content = (<OrderPrintedMaterials />);
                    break;
               case 'Practice Insights newsletter':
                    content = (<PracticeInsightsNewsletter formDescription={form.formDescription} />);
                    break;
               case 'HR Professionals':
                    content = (<HRProfessionals />);
                    break;
               case 'Brokers':
                    content = (<Brokers />);
                    break;
               case 'VetHelpline Sign Up':
                    content = (<VetHelplineSignup formDescription={form.formDescription} />);
                    break;

               case 'Veterinarians Sign Up':
                    content = (<VeterinariansSignUp />);
                    break;
               case 'Benefits Brokers Sign Up':
                    content = (<BenefitsbrokerssignUp />);
                    break;
               default:
                    content = (<PPC contentName={form.contentName} />);
          }

          return (
               <>
                    {content}
               </>
          )
     }
}
export default ContentfulBlockSubmitForm;
