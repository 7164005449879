import React, { Component } from 'react'

class PPC extends Component {
    constructor(props) {
        super(props);

        const { contentName } = this.props;
        const petTypeDefault = (typeof contentName != 'undefined' && contentName.toLowerCase().includes('cat')) ? 'Cat' : 'Dog';

        this.state = {
            petType: petTypeDefault,
            petName: '',
            zipCode: '',
            isSubmitted: false
        }
    }

    onPetNameChanged = (e) => this.setState({ petName: e.target.value })
    onPetTypeChanged = (e) => this.setState({ petType: e.target.value })

    onZipCodeChanged = (e) => {
        if (/^\d*$/.test(e.target.value)) {
            this.setState({ zipCode: e.target.value })
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.setState({ isSubmitted: true })
        const { petType, petName, zipCode } = this.state;

        var getOmValue = localStorage.getItem("Petinsurance_OM");
        var omValue = getOmValue != null ? `OM=${getOmValue}&` : '';

        var getPhoneValue = localStorage.getItem("Petinsurance_TrackingPhone");
        var phoneValue = getPhoneValue != null ? `ph=${getPhoneValue}&` : '';

        if (petName && this.zipCodeValidation(zipCode)) {
            window.location.replace(`https://${process.env.GATSBY_QECURLBYENVIRONMENT}/?${omValue}${phoneValue}ENROLL=N&speciesCode=${petType}&petName=${petName}&zipCode=${zipCode}`)
        }
    }

    zipCodeValidation = (zipCode) => {
        const regex = /^\d{5}(-\d{4})?$/;
        return regex.test(zipCode);
    }

    render() {
        return (
            <form method="post" id="mainForm" onSubmit={this.onSubmit}>
                <div className="quote-form-line">
                    <div className="form-group pet-type">
                        <label htmlFor="petType">
                            I have a
                            </label>
                        <div className="selectpicker-container form-container">
                            <select
                                name="petType"
                                className="landingpage selectpicker"
                                onChange={this.onPetTypeChanged}
                                defaultValue={this.state.petType}
                            >
                                <option value="Dog">Dog</option>
                                <option value="Cat">Cat</option>
                            </select>
                        </div>
                    </div>
                    <div className={`form-group pet-name ${this.state.isSubmitted && !this.state.petName ? 'error' : ''}`}>
                        <label
                            htmlFor="petName"
                            className="control-label"
                        >
                            named
                            </label>
                        <div className="form-container">
                            <input
                                id="petName"
                                type="text"
                                name="petName"
                                className="form-control"
                                onChange={this.onPetNameChanged}
                                value={this.state.petName}
                                maxLength={50}
                                aria-describedby="pet-name-validation"
                            />
                            <div className="help-block with-errors" id="pet-name-validation">
                                Please enter pet name
                                </div>
                        </div>
                    </div>
                </div>
                <div className="quote-form-line">
                    <div className={`form-group zip-code ${this.state.isSubmitted && !this.zipCodeValidation(this.state.zipCode) ? 'error' : ''}`}>
                        <label
                            htmlFor="zipCode"
                            className="control-label"
                        >
                            We live in
                            </label>
                        <div className="form-container">
                            <input
                                id="zipCode"
                                type="text"
                                name="zipCode"
                                className="form-control"
                                placeholder="ZIP Code"
                                onChange={this.onZipCodeChanged}
                                value={this.state.zipCode}
                                maxLength={5}
                                aria-describedby="zip-code-validation"
                            />
                            <div className="help-block with-errors" id="zip-code-validation">
                                Please enter a valid zip code
                                </div>
                        </div>
                    </div>
                </div>
                <div className="topButton">
                    <input
                        className="button-yellow get-started-btn"
                        type="submit"
                        value="Get Started"
                    />
                </div>
            </form>
        )
    }
}

export default PPC;
