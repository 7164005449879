import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import { validateEmail, sendEmail } from '../../utilities';

class Brokers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: '',
            lastName: '',
            companyName: '',
            averageClient: '',
            state: '',
            phoneNumber: '',
            email: '',
            isSubmitted: false,
            emailError: '',
            isSuccess: false
        }
    }

    onFirstNameChanged = (e) => this.setState({ firstName: e.target.value })
    onLastNameChanged = (e) => this.setState({ lastName: e.target.value })
    onCompanyNameChanged = (e) => this.setState({ companyName: e.target.value })
    onEmployChanged = (e) => this.setState({ averageClient: e.target.value })
    onStateChanged = (e) => this.setState({ state: e.target.value })
    onPhoneNumberChanged = (e) => this.setState({ phoneNumber: e.target.value })
    onEmailChanged = (e) => this.setState({ email: e.target.value })

    onSubmit = (e) => {
        e.preventDefault();
        const { firstName, lastName, companyName, averageClient, state, phoneNumber, email, isSubmitted } = this.state;

        if (!isSubmitted) {
            this.setState({ isSubmitted: true })
        }

        if (!email) {
            this.setState({ emailError: 'Please enter email' })
        } else if (!validateEmail(email)) {
            this.setState({ emailError: 'Please enter a valid email' })
        } else if (firstName && lastName && companyName && averageClient && state && phoneNumber && !phoneNumber.includes('_')) {
            const dataToSend = {
                "First name": firstName,
                "Last name": lastName,
                "Company name": companyName,
                "Average client": averageClient,
                "State": state,
                "Phone number": phoneNumber,
                "Email address": email,
                'Form Name': 'brokers'
            }

            sendEmail(dataToSend, process.env.EMPLOYERS_EMAIL_FROM, process.env.EMPLOYERS_EMAIL_TO);
            this.setState({ isSuccess: true });
        }
    }

    render() {
        return (
            <>
                {
                    !this.state.isSuccess ? (
                        <form method="post" id="mainForm3" className="main-form" onSubmit={this.onSubmit}>
                            <div className="row">
                                <div className={`col-sm-6 form-group col-left full-name ${this.state.isSubmitted && !this.state.firstName ? 'error' : ''}`}>
                                    <label
                                        htmlFor="firstName"
                                        className="control-label"
                                    >
                                        First name
                                </label>
                                    <div className="form-container">
                                        <input
                                            id="firstName"
                                            type="text"
                                            name="firstName"
                                            className="form-control"
                                            placeholder="First name"
                                            onChange={this.onFirstNameChanged}
                                            value={this.state.firstName}
                                            maxLength={50}
                                            aria-describedby="first-name-validation"
                                        />
                                        <div className="help-block with-errors" id="first-name-validation">Please enter first name</div>
                                    </div>
                                </div>
                                <div className={`col-sm-6 form-group col-right ${this.state.isSubmitted && !this.state.lastName ? 'error' : ''}`}>
                                    <label
                                        htmlFor="lastName"
                                        className="control-label"
                                    >
                                        Last name
                            </label>
                                    <div className="form-container">
                                        <input
                                            id="lastName"
                                            type="text"
                                            name="lastName"
                                            className="form-control"
                                            placeholder="Last name"
                                            onChange={this.onLastNameChanged}
                                            value={this.state.lastName}
                                            maxLength={50}
                                            aria-describedby="last-name-validation"
                                        />
                                        <div className="help-block with-errors" id="last-name-validation">Please enter last name</div>
                                    </div>
                                </div>
                                <div className={`col-sm-6 form-group col-left ${this.state.isSubmitted && !this.state.companyName ? 'error' : ''}`}>
                                    <label
                                        htmlFor="companyName"
                                        className="control-label"
                                    >
                                        Company name
                            </label>
                                    <div className="form-container">
                                        <input
                                            id="companyName"
                                            type="text"
                                            name="companyName"
                                            className="form-control"
                                            placeholder="Company name"
                                            onChange={this.onCompanyNameChanged}
                                            value={this.state.companyName}
                                            maxLength={50}
                                            aria-describedby="company-name-validation"
                                        />
                                        <div className="help-block with-errors" id="company-name-validation">Please enter Company name</div>
                                    </div>
                                </div>
                                <div className={`col-sm-6 form-group col-right ${this.state.isSubmitted && !this.state.averageClient ? 'error' : ''}`}>
                                    <label
                                        htmlFor="averageClient"
                                        className="control-label"
                                    >
                                        Average client size
                            </label>
                                    <div className="form-container">
                                        <input
                                            id="averageClient"
                                            type="text"
                                            name="averageClient"
                                            className="form-control"
                                            placeholder="Average client size"
                                            onChange={this.onEmployChanged}
                                            value={this.state.averageClient}
                                            maxLength={50}
                                            aria-describedby="average-client-size-validation"
                                        />
                                        <div className="help-block with-errors" id="average-client-size-validation">Please enter average client size</div>
                                    </div>
                                </div>
                                <div className="col-sm-6 form-group col-left state">
                                    <label htmlFor="state">
                                        State
                            </label>
                                    <div className={`selectpicker-container form-container ${this.state.isSubmitted && !this.state.state ? 'error' : ''}`}>
                                        <select
                                            name="state"
                                            className="state"
                                            onChange={this.onStateChanged}
                                            defaultValue={this.state.state}
                                            aria-label="Select a state"
                                            aria-describedby="state-validation"
                                        >
                                            <option value="">Select</option>
                                            <option value="AK">AK</option>
                                            <option value="AL">AL</option>
                                            <option value="AR">AR</option>
                                            <option value="AZ">AZ</option>
                                            <option value="CA">CA</option>
                                            <option value="CO">CO</option>
                                            <option value="CT">CT</option>
                                            <option value="DE">DE</option>
                                            <option value="FL">FL</option>
                                            <option value="GA">GA</option>
                                            <option value="HI">HI</option>
                                            <option value="IA">IA</option>
                                            <option value="ID">ID</option>
                                            <option value="IL">IL</option>
                                            <option value="IN">IN</option>
                                            <option value="KS">KS</option>
                                            <option value="KY">KY</option>
                                            <option value="LA">LA</option>
                                            <option value="MA">MA</option>
                                            <option value="MD">MD</option>
                                            <option value="ME">ME</option>
                                            <option value="MI">MI</option>
                                            <option value="MN">MN</option>
                                            <option value="MO">MO</option>
                                            <option value="MS">MS</option>
                                            <option value="MT">MT</option>
                                            <option value="NC">NC</option>
                                            <option value="ND">ND</option>
                                            <option value="NE">NE</option>
                                            <option value="NH">NH</option>
                                            <option value="NJ">NJ</option>
                                            <option value="NM">NM</option>
                                            <option value="NV">NV</option>
                                            <option value="NY">NY</option>
                                            <option value="OH">OH</option>
                                            <option value="OK">OK</option>
                                            <option value="OR">OR</option>
                                            <option value="PA">PA</option>
                                            <option value="RI">RI</option>
                                            <option value="SC">SC</option>
                                            <option value="SD">SD</option>
                                            <option value="TN">TN</option>
                                            <option value="TX">TX</option>
                                            <option value="UT">UT</option>
                                            <option value="VA">VA</option>
                                            <option value="VT">VT</option>
                                            <option value="WA">WA</option>
                                            <option value="WI">WI</option>
                                            <option value="WV">WV</option>
                                            <option value="WY">WY</option>
                                        </select>
                                        <div className="help-block with-errors" id="state-validation">
                                            Please select a state
                                </div>
                                    </div>
                                </div>
                                <div className={`col-sm-6 form-group col-right ${this.state.isSubmitted && (!this.state.phoneNumber || this.state.phoneNumber.includes('_')) ? 'error' : ''}`}>
                                    <label
                                        htmlFor="phoneNumber"
                                        className="control-label"
                                    >
                                        Phone number
                                </label>
                                    <div className="form-container">
                                        <InputMask
                                            id="phoneNumber"
                                            type="text"
                                            className="form-control"
                                            placeholder="(000) 000 - 0000"
                                            mask="(999) 999 - 9999"
                                            value={this.state.phoneNumber}
                                            onChange={this.onPhoneNumberChanged}
                                            aria-describedby="phone-number-validation"
                                        />
                                        <div className="help-block with-errors" id="phone-number-validation">
                                            {this.state.phoneNumber ? 'Please enter a valid phone number' : 'Please enter phone number'}
                                        </div>
                                    </div>
                                </div>
                                <div className={`col-sm-12 form-group ${(this.state.isSubmitted && !validateEmail(this.state.email)) ? 'error' : ''}`}>
                                    <label
                                        htmlFor="email"
                                        className="control-label"
                                    >
                                        Email address
                                </label>
                                    <div className="form-container">
                                        <input
                                            id="email"
                                            type="text"
                                            name="email"
                                            className="form-control"
                                            placeholder="Enter your email address"
                                            onChange={this.onEmailChanged}
                                            value={this.state.email}
                                            maxLength={50}
                                            aria-describedby="email-address-validation"
                                        />
                                        <div className="help-block with-errors" id="email-address-validation">{this.state.emailError}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="topButton">
                                <input
                                    className="button-yellow get-started-btn"
                                    type="submit"
                                    value="Contact Me"
                                />
                            </div>
                        </form>
                    ) : (
                            <div className="form-submit-success">
                                <p>Thank you for your interest in Nationwide as a voluntary benefit. A member of our team will contact you shortly.</p>
                            </div>
                        )
                }
            </>
        )
    }
}
export default Brokers;
