import React, { Component } from 'react';
import { validateEmail, sendEmail } from '../../utilities';
import { RichText } from '../../richTextOptions';

class PracticeInsightsNewsletter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            practiceName: '',
            email: '',
            checkBox: false,
            isSubmitted: false,
            emailError: '',
            isSuccess: false
        }
    }

    onPracticeNameChanged = (e) => this.setState({ practiceName: e.target.value })
    onEmailChanged = (e) => this.setState({ email: e.target.value })
    onCheckBox = () => this.setState({ checkBox: !this.state.checkBox })

    onSubmit = (e) => {
        e.preventDefault();
        const { practiceName, email, checkBox, isSubmitted } = this.state;

        if (!isSubmitted) {
            this.setState({ isSubmitted: true })
        }

        if (!email) {
            this.setState({ emailError: 'Please enter email' })
        } else if (!validateEmail(email)) {
            this.setState({ emailError: 'Please enter a valid email' })
        } else if (practiceName && checkBox) {
            const dataToSend = {
                "Practice name": practiceName,
                "Email adress": email,
                "I'd like to receive the monthly Practice Insights email newsletter": checkBox ? 'Yes' : 'No',
            }

            sendEmail(dataToSend, process.env.VETS_EMAIL_FROM, process.env.VETS_EMAIL_TO);
            this.setState({ isSuccess: true });
        }
    }

    render() {
        return (
            <>
                {
                    !this.state.isSuccess ? (
                        <form method="post" id="mainForm3" className="main-form" onSubmit={this.onSubmit}>
                            <div className="row">
                                <div className="col-sm-12 description-newsletter">
                                    <RichText data={this.props.formDescription}></RichText>
                                </div>
                                <div className={`col-sm-6 form-group ${this.state.isSubmitted && !this.state.practiceName ? 'error' : ''}`}>
                                    <label
                                        htmlFor="practiceName"
                                        className="control-label"
                                    >
                                        Practice name
                            </label>
                                    <div className="form-container">
                                        <input
                                            id="practiceName"
                                            type="text"
                                            name="practiceName"
                                            className="form-control"
                                            placeholder="Practice name"
                                            onChange={this.onPracticeNameChanged}
                                            value={this.state.practiceName}
                                            maxLength={50}
                                            aria-describedby="practice-name-validation"
                                        />
                                        <div className="help-block with-errors" id="practice-name-validation">
                                            Please enter practice name
                                </div>
                                    </div>
                                </div>
                                <div className={`col-sm-6 form-group ${(this.state.isSubmitted && !validateEmail(this.state.email)) ? 'error' : ''}`}>
                                    <label
                                        htmlFor="emai"
                                        className="control-label"
                                    >
                                        Email address
                            </label>
                                    <div className="form-container">
                                        <input
                                            id="emai"
                                            type="text"
                                            name="email"
                                            className="form-control"
                                            placeholder="Email address"
                                            onChange={this.onEmailChanged}
                                            value={this.state.email}
                                            maxLength={50}
                                            aria-describedby="email-validation"
                                        />
                                        <div className="help-block with-errors" id="email-validation">{this.state.emailError}</div>
                                    </div>
                                </div>
                                <div className={`col-sm-12 form-group form-group-checkbox ${this.state.isSubmitted && this.state.checkBox != true ? 'error' : ''}`}>
                                    <div className="form-container">
                                        <label className="checkbox-practice" aria-describedby="checkbox-practice-validation">I’d like to receive the monthly Practice Insights email newsletter.
                                    <input type="checkbox" onClick={this.onCheckBox} defaultChecked={this.state.checkBox} />
                                            <span className="checkmark"></span>
                                        </label>
                                        <div className="help-block with-errors" aria-label="checkbox-practice-validation">This is a required field</div>
                                    </div>
                                </div>
                            </div>
                            <div className="topButton">
                                <input
                                    className="button-yellow get-started-btn"
                                    type="submit"
                                    value="Sign up now"
                                />
                            </div>
                        </form>
                    ) : (
                            <div className="form-submit-success">
                                <p>Thank you for contacting us! You will begin receiving Nationwide's e-mail newsletter, Practice Insights.</p>
                            </div>
                        )
                }
            </>
        )
    }
}
export default PracticeInsightsNewsletter;
