import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import { validateEmail, sendEmail } from '../../utilities';

class OrderPrintedMaterials extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fullName: '',
            practiceName: '',
            practiceStreetAddress: '',
            city: '',
            state: '',
            zipCode: '',
            phoneNumber: '',
            email: '',
            isSubmitted: false,
            emailError: '',
            isSuccess: false
        }
    }

    onFullNameChanged = (e) => this.setState({ fullName: e.target.value })
    onPracticeNameChanged = (e) => this.setState({ practiceName: e.target.value })
    onPracticeStreetAddressChanged = (e) => this.setState({ practiceStreetAddress: e.target.value })
    onCityChanged = (e) => this.setState({ city: e.target.value })
    onStateChanged = (e) => this.setState({ state: e.target.value })
    onPhoneNumberChanged = (e) => this.setState({ phoneNumber: e.target.value })
    onEmailChanged = (e) => this.setState({ email: e.target.value })

    onZipCodeChanged = (e) => {
        if (/^\d*$/.test(e.target.value)) {
            this.setState({ zipCode: e.target.value })
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
        const { fullName, practiceName, practiceStreetAddress, city, state, zipCode, phoneNumber, email, isSubmitted } = this.state;

        if (!isSubmitted) {
            this.setState({ isSubmitted: true })
        }

        if (!email) {
            this.setState({ emailError: 'Please enter email' })
        } else if (!validateEmail(email)) {
            this.setState({ emailError: 'Please enter a valid email' })
        } else if (fullName && practiceName && practiceStreetAddress && city && state && zipCode && phoneNumber && !phoneNumber.includes('_')) {
            const dataToSend = {
                "Full name": fullName,
                "Practice name": practiceName,
                "Practice street address": practiceStreetAddress,
                "City": city,
                "State": state,
                "Zip code": zipCode,
                "Phone number": phoneNumber,
                "Email address": email,
                'Form Name': 'order printed material'
            }

            sendEmail(dataToSend, process.env.VETS_EMAIL_FROM, process.env.VETS_EMAIL_TO);
            this.setState({ isSuccess: true });
        }
    }

    zipCodeValidation = (zipCode) => {
        const regex = /^\d{5}(-\d{4})?$/;
        return regex.test(zipCode);
    }

    render() {
        return (
            <>
                {
                    !this.state.isSuccess ? (
                        <form method="post" id="mainForm3" className="main-form" onSubmit={this.onSubmit}>
                            <div className="row">
                                <div className={`col-sm-6 form-group full-name col-sm-6-left ${this.state.isSubmitted && !this.state.fullName ? 'error' : ''}`}>
                                    <label
                                        htmlFor="fullName"
                                        className="control-label"
                                    >
                                        Full name
                            </label>
                                    <div className="form-container">
                                        <input
                                            id="fullName"
                                            type="text"
                                            name="fullName"
                                            className="form-control"
                                            placeholder="Full name"
                                            onChange={this.onFullNameChanged}
                                            value={this.state.fullName}
                                            maxLength={50}
                                            aria-describedby="full-name-validation"
                                        />
                                        <div className="help-block with-errors" id="full-name-validation">
                                            Please enter full name
                                </div>
                                    </div>
                                </div>
                                <div className={`col-sm-6 form-group col-sm-6-right practice-name ${this.state.isSubmitted && !this.state.practiceName ? 'error' : ''}`}>
                                    <label
                                        htmlFor="practiceName"
                                        className="control-label"
                                    >
                                        Practice name
                            </label>
                                    <div className="form-container">
                                        <input
                                            id="practiceName"
                                            type="text"
                                            name="practiceName"
                                            className="form-control"
                                            placeholder="Practice name"
                                            onChange={this.onPracticeNameChanged}
                                            value={this.state.practiceName}
                                            maxLength={50}
                                            aria-describedby="practice-validation"
                                        />
                                        <div className="help-block with-errors" id="practice-validation">
                                            Please enter practice name
                                </div>
                                    </div>
                                </div>
                                <div className={`col-sm-12 form-group ${this.state.isSubmitted && !this.state.practiceStreetAddress ? 'error' : ''}`}>
                                    <label
                                        htmlFor="practiceStreetAddress"
                                        className="control-label"
                                    >
                                        Practice street address
                            </label>
                                    <div className="form-container">
                                        <input
                                            id="practiceStreetAddress"
                                            type="text"
                                            name="practiceStreetAddress"
                                            className="form-control"
                                            placeholder="Enter your practice address"
                                            onChange={this.onPracticeStreetAddressChanged}
                                            value={this.state.practiceStreetAddress}
                                            maxLength={50}
                                            aria-describedby="street-address-validation"
                                        />
                                        <div className="help-block with-errors" id="street-address-validation">
                                            Please enter Practice Street Address
                                </div>
                                    </div>
                                </div>
                                <div className={`col-sm-4 form-group ${this.state.isSubmitted && !this.state.city ? 'error' : ''}`}>
                                    <label
                                        htmlFor="city"
                                        className="control-label"
                                    >
                                        City
                            </label>
                                    <div className="form-container">
                                        <input
                                            id="city"
                                            type="text"
                                            name="city"
                                            className="form-control"
                                            placeholder="City"
                                            onChange={this.onCityChanged}
                                            value={this.state.city}
                                            maxLength={50}
                                            aria-describedby="city-validation"
                                        />
                                        <div className="help-block with-errors" id="city-validation">
                                            Please enter City
                                </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 form-group state">
                                    <label htmlFor="state">
                                        State
                            </label>
                                    <div className={`selectpicker-container form-container ${this.state.isSubmitted && !this.state.state ? 'error' : ''}`}>
                                        <select
                                            name="state"
                                            className="state"
                                            onChange={this.onStateChanged}
                                            defaultValue={this.state.state}
                                            aria-label="Select a state"
                                            aria-describedby="state-validation"
                                        >
                                            <option value="">Select</option>
                                            <option value="AK">AK</option>
                                            <option value="AL">AL</option>
                                            <option value="AR">AR</option>
                                            <option value="AZ">AZ</option>
                                            <option value="CA">CA</option>
                                            <option value="CO">CO</option>
                                            <option value="CT">CT</option>
                                            <option value="DE">DE</option>
                                            <option value="FL">FL</option>
                                            <option value="GA">GA</option>
                                            <option value="HI">HI</option>
                                            <option value="IA">IA</option>
                                            <option value="ID">ID</option>
                                            <option value="IL">IL</option>
                                            <option value="IN">IN</option>
                                            <option value="KS">KS</option>
                                            <option value="KY">KY</option>
                                            <option value="LA">LA</option>
                                            <option value="MA">MA</option>
                                            <option value="MD">MD</option>
                                            <option value="ME">ME</option>
                                            <option value="MI">MI</option>
                                            <option value="MN">MN</option>
                                            <option value="MO">MO</option>
                                            <option value="MS">MS</option>
                                            <option value="MT">MT</option>
                                            <option value="NC">NC</option>
                                            <option value="ND">ND</option>
                                            <option value="NE">NE</option>
                                            <option value="NH">NH</option>
                                            <option value="NJ">NJ</option>
                                            <option value="NM">NM</option>
                                            <option value="NV">NV</option>
                                            <option value="NY">NY</option>
                                            <option value="OH">OH</option>
                                            <option value="OK">OK</option>
                                            <option value="OR">OR</option>
                                            <option value="PA">PA</option>
                                            <option value="RI">RI</option>
                                            <option value="SC">SC</option>
                                            <option value="SD">SD</option>
                                            <option value="TN">TN</option>
                                            <option value="TX">TX</option>
                                            <option value="UT">UT</option>
                                            <option value="VA">VA</option>
                                            <option value="VT">VT</option>
                                            <option value="WA">WA</option>
                                            <option value="WI">WI</option>
                                            <option value="WV">WV</option>
                                            <option value="WY">WY</option>
                                        </select>
                                        <div className="help-block with-errors" id="state-validation">
                                            Please select a state
                            </div>
                                    </div>
                                </div>

                                <div className={`col-sm-4 form-group zip-code ${this.state.isSubmitted && !this.zipCodeValidation(this.state.zipCode) ? 'error' : ''}`}>
                                    <label
                                        htmlFor="zipCode"
                                        className="control-label"
                                    >
                                        Zip code
                            </label>
                                    <div className="form-container">
                                        <input
                                            id="zipCode"
                                            type="text"
                                            name="zipCode"
                                            className="form-control"
                                            placeholder="ZIP Code"
                                            onChange={this.onZipCodeChanged}
                                            value={this.state.zipCode}
                                            maxLength={5}
                                            aria-describedby="zip-code-validation"
                                        />
                                        <div className="help-block with-errors" id="zip-code-validation">
                                            Please enter a valid zip code
                                        </div>
                                    </div>
                                </div>
                                <div className={`col-sm-6 form-group col-right ${this.state.isSubmitted && (!this.state.phoneNumber || this.state.phoneNumber.includes('_')) ? 'error' : ''}`}>
                                    <label
                                        htmlFor="phoneNumber"
                                        className="control-label"
                                    >
                                        Phone number
                                    </label>
                                    <div className="form-container">
                                        <InputMask
                                            id="phoneNumber"
                                            type="text"
                                            className="form-control"
                                            placeholder="(000) 000 - 0000"
                                            mask="(999) 999 - 9999"
                                            value={this.state.phoneNumber}
                                            onChange={this.onPhoneNumberChanged}
                                            aria-describedby="phone-number-validation"
                                        />
                                        <div className="help-block with-errors" id="phone-number-validation">
                                            {this.state.phoneNumber ? 'Please enter a valid phone number' : 'Please enter phone number'}
                                        </div>
                                    </div>
                                </div>
                                <div className={`col-sm-6 form-group col-sm-6-right ${(this.state.isSubmitted && !validateEmail(this.state.email)) ? 'error' : ''}`}>
                                    <label
                                        htmlFor="email"
                                        className="control-label"
                                    >
                                        Email address
                            </label>
                                    <div className="form-container">
                                        <input
                                            id="email"
                                            type="text"
                                            name="email"
                                            className="form-control"
                                            placeholder="Email address"
                                            onChange={this.onEmailChanged}
                                            value={this.state.email}
                                            maxLength={50}
                                            aria-describedby="email-validation"
                                        />
                                        <div className="help-block with-errors" id="email-validation">{this.state.emailError}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="topButton">
                                <input
                                    className="button-yellow get-started-btn"
                                    type="submit"
                                    value="Submit Now"
                                />
                            </div>
                        </form>
                    ) : (
                            <div className="form-submit-success">
                                <p>Thank you for your order. You should receive materials within two weeks. If you would like to order other materials, please contact Vet Services at 866-838-4874.</p>
                            </div>
                        )
                }
            </>
        )
    }
}

export default OrderPrintedMaterials;
