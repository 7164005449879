import React, { useState } from 'react'
import { RichText } from '../../richTextOptions';
import Spinner from 'react-bootstrap/Spinner'

const VetHelplineSignup = ({ formDescription }) => {

    const [loading, setLoading] = useState()
    const [isSuccess, setIsSuccess] = useState(false)
    const [email, setEmail] = useState()
    const [errorEmail, setErrorEmail] = useState()
    const REGEX_EMAIL = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/

    const onSubmitEmail = (e) => {
        event.preventDefault()

        if (!email) {
            setErrorEmail('Email is required')
        } else if (!REGEX_EMAIL.test(email)) {
            setErrorEmail('Not a valid email')
        } else {
            setIsSuccess(true)
        }
    }

    const onChangeEmail = (e) => {
        setEmail(e.target.value)
    }

    return (
        <div className="sign-up-wrapper">
            {
                formDescription ? (
                    <div className="form-des">
                        <RichText data={formDescription}></RichText>
                    </div>
                ) : ('')
            }
            <div className="form-wrapper">
                {
                    !isSuccess ?
                        <>
                            <form onSubmit={onSubmitEmail}>
                                <input type="text" placeholder="Enter email address" onChange={onChangeEmail} autoComplete="off" />
                                <div className="button-wrapper">
                                    <button type="submit" className="button">
                                        {
                                            loading ? (
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            ) : (
                                                <span>Sign up</span>
                                            )
                                        }
                                    </button>
                                </div>
                            </form>
                            <div className="form-mess">
                                {errorEmail && <div className="fail">{errorEmail}</div>}
                            </div>
                        </> :
                        <div className="success">Thank For Your Sign Up !!!</div>
                }
            </div>
        </div>
    )
}

export default VetHelplineSignup
