import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { validateEmail } from '../../utilities';
import Axios from 'axios';

const PopupSignUp = ({ show, setShow }) => {
     const [isSuccess, setIsSuccess] = useState(false);
     const [email, setEmail] = useState('');
     const [errorEmail, setErrorEmail] = useState('');

     const handleClose = () => {
          setShow(false);
          setIsSuccess(false);
          setErrorEmail('');
     };

     const onSubmitEmail = (e) => {
          e.preventDefault();

          if (!email) {
               setErrorEmail('This field is required.');
          } else if (!validateEmail(email)) {
               setErrorEmail('Email is not valid');
          } 

          if(email && validateEmail(email)){
               Axios({
                    method: 'post',
                    url: `https://mc9bsj012b7g46tfb-zv-r51-s74.pub.sfmc-content.com/zcfx3wd5i5n?email=${email}`
               }).then(
                    (response) => {  
                         // console.log(response);
                    },
                    (reject) => {
                         // console.log(reject)
                    }
               );
               setIsSuccess(true);
               setErrorEmail('');
          }
     };

     const onChangeEmail = (e) => {
          setEmail(e.target.value);
     };

     return (
          <>
               <Modal className="popup-signup" centered show={show} onHide={handleClose}>
                    <div className="container">
                         <Modal.Header closeButton></Modal.Header>
                         <Modal.Body className="popup-signup-text">
                              {!isSuccess ? (
                                   <>
                                        <form onSubmit={onSubmitEmail}>
                                             <h2>Sign up for our eNewsletter</h2>
                                             <div className="input-email">
                                                  <input type="text" className="form-control" placeholder="Enter email address" onChange={onChangeEmail} />
                                                  <div className="form-mess">{errorEmail && <div className="fail">{errorEmail}</div>}</div>
                                             </div>

                                             <div className="button-wrapper">
                                                  <button type="submit" className="button">
                                                       <span>Submit</span>
                                                  </button>
                                             </div>
                                        </form>
                                   </>
                              ) : (
                                   <div className="success"><h2>Thanks for subscribing! We’ll be sure to keep you updated with the latest tips and research.</h2></div>
                              )}
                         </Modal.Body>
                    </div>
               </Modal>
          </>
     );
};

const VeterinariansSignUp = () => {
     const [show, setShow] = useState(false);
     const handleShow = () => setShow(true);

     return (
          <div className="sign-up-wrapper">
               <PopupSignUp show={show} setShow={setShow} />
               <Button onClick={handleShow}>Sign up now</Button>
          </div>
     );
};

export default VeterinariansSignUp;
