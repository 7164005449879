import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useStaticQuery, graphql } from 'gatsby';

import ContentfulBlockDoesMyOrganizationQualify from '../ContentfulBlockDoesMyOrganizationQualify';
const PopupSignUp = ({ show, setShow }) => {

     const handleClose = () => {
          setShow(false);
     };

     const getDataBlockDoesMyOrganizationQualify = useStaticQuery(
          graphql`
               query {
                    contentfulBlockDoesMyOrganizationQualify {
                         id
                         contentful_id
                         contentName
                         myOrganizationQualify {
                              raw
                         }
                         buttonChooseUsers {
                              ...ContentfulNavgationButtonChooseOrgFields
                         }
                         textDoesDescription{
                              raw
                         }
                    }
               }
          `
     );

     const dataDoesMyOrganizationQualify = getDataBlockDoesMyOrganizationQualify.contentfulBlockDoesMyOrganizationQualify;

     return (
          <>
               <Modal className="popupdoes" show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
                    <div className="container">
                         <Modal.Header closeButton></Modal.Header>
                         <Modal.Body className="text">
                              <ContentfulBlockDoesMyOrganizationQualify setShowPopup={setShow} {...dataDoesMyOrganizationQualify} />
                         </Modal.Body>
                    </div>
               </Modal>
          </>
     );
};

const VeterinariansSignUp = () => {
     const [show, setShow] = useState(false);
     const handleShow = () => setShow(true);

     return (
          <div>
               <PopupSignUp show={show} setShow={setShow} />
               <Button onClick={handleShow}>Sign up</Button>
          </div>
     );
};

export default VeterinariansSignUp;
